import { IconArrowBack } from "@tabler/icons-react"
import { Button } from "@/shared/ui/button"

export function MagicLinkSent({
  onButtonClick,
}: {
  onButtonClick: () => void
}) {
  return (
    <>
      <h1 className="text-h2">Проверьте почту</h1>
      <p className="mt-1 text-big text-text-secondary">
        Используйте отправленную на вашу почту ссылку, чтобы войти во Flow
      </p>
      <Button className="mt-8" onClick={onButtonClick}>
        <IconArrowBack size={16} className="mr-2" /> Изменить почту
      </Button>
    </>
  )
}
