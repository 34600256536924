import { zodResolver } from "@hookform/resolvers/zod"
import {
  json,
  redirect,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node"
import { useFetcher } from "@remix-run/react"
import type { FieldErrors } from "react-hook-form"
import { getValidatedFormData, useRemixForm } from "remix-hook-form"
import { z } from "zod"
import { getAuthFromRequest, jweSession } from "@/features/auth.server"
import { api, ApiError } from "@/shared/api.server"
import { Button } from "@/shared/ui/button"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormProvider,
} from "@/shared/ui/form"
import { Input } from "@/shared/ui/input"
import { Layout, MagicLinkSent } from "./ui"

export const meta: MetaFunction = () => [{ title: "Вход во Flow" }]

export async function loader({ request }: LoaderFunctionArgs) {
  const auth = await getAuthFromRequest(request, { isRequired: false })
  const url = new URL(request.url)
  const redirectUrl = url.searchParams.get("redirect_url")

  if (auth.viewer) {
    return redirect(redirectUrl ?? "/")
  }

  return null
}

const formSchema = z.object({
  email: z
    .string()
    .email("Некорректный email")
    .endsWith("@qqcoffee.ru", "Email должен быть на домене @qqcoffee.ru"),
})

type FormSchema = z.infer<typeof formSchema>

const resolver = zodResolver(formSchema)

export default function SignInPage() {
  const fetcher = useFetcher<typeof action>()
  const form = useRemixForm<FormSchema>({
    fetcher,
    resolver,
    defaultValues: {
      email: "",
    },
  })

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    control,
  } = form

  const isMagicLinkSent = isSubmitSuccessful && fetcher.data?.ok

  return (
    <Layout viewKey={isMagicLinkSent ? "sent" : "form"}>
      {isMagicLinkSent ? (
        <MagicLinkSent
          onButtonClick={() => {
            form.reset()
          }}
        />
      ) : (
        <FormProvider {...form}>
          <fetcher.Form onSubmit={handleSubmit} method="post">
            <h1 className="text-h2">Войдите</h1>
            <p className="mt-1 text-big text-text-secondary">
              чтобы перейти во Flow
            </p>
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="mt-6 text-start">
                  <FormLabel>Корпоративная почта</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder="vivanov@qqcoffee.ru"
                      autoComplete="off"
                      {...field}
                      disabled={isSubmitting}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              colorScheme="orange"
              size="md"
              disabled={!isDirty || isSubmitting}
              className="mt-4 w-full"
            >
              Продолжить
            </Button>
          </fetcher.Form>
        </FormProvider>
      )}
    </Layout>
  )
}

export async function action({ request }: ActionFunctionArgs) {
  const {
    errors,
    data,
    receivedValues: defaultValues,
  } = await getValidatedFormData<FormSchema>(request, resolver)
  if (errors) {
    return json({ errors, defaultValues, ok: false }, 400)
  }

  const session = await jweSession.getSession(request.headers.get("Cookie"))
  const url = new URL(request.url)
  const redirectUrl = url.searchParams.get("redirect_url")

  try {
    const { jwe } = await api.auth.magic_links
      .$post({
        json: { email: data.email },
      })
      .then((response) => response.json())

    session.set("jwe", jwe)

    if (redirectUrl) {
      session.set("redirectUrl", redirectUrl)
    }

    return json(
      { ok: true },
      {
        headers: {
          "Set-Cookie": await jweSession.commitSession(session),
        },
      },
    )
  } catch (error) {
    return json({
      ok: false,
      errors: {
        email: {
          type: "validate",
          message:
            error instanceof ApiError
              ? error.response.status === 410
                ? "Пользователь с такой почтой не найден."
                : error.response.status === 403
                  ? "Пользователь забанен."
                  : error.message
              : error instanceof Error
                ? `Ошибка: ${error.message}, ${error.stack}`
                : "Неизвестная ошибка",
        },
      } satisfies FieldErrors<FormSchema>,
    })
  }
}
