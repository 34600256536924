import { AnimatePresence, motion } from "framer-motion"
import type { ReactNode } from "react"
import useMeasure from "react-use-measure"

export function Layout({
  children,
  viewKey,
}: {
  children: ReactNode
  viewKey: string
}) {
  const [ref, { height }] = useMeasure()

  return (
    <div className="bg-noisy flex min-h-[100vh] w-full items-start justify-center bg-cover bg-repeat p-2 pt-72">
      <motion.main
        animate={{ height: height === 0 ? "auto" : height }}
        transition={{
          duration: 0.27,
          ease: [0.25, 1, 0.5, 1],
        }}
        className="w-[400px] rounded-lg bg-bg-primary text-center shadow-light"
      >
        <div ref={ref} className="px-10 py-8">
          <img
            src="/flow-logo-border.svg"
            alt="Логотип Flow"
            className="mb-6 inline-block w-32"
          />
          <AnimatePresence initial={false} mode="popLayout">
            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  scale: 0.96,
                  transition: {
                    ease: [0.26, 0.08, 0.25, 1],
                    duration: 0.22,
                  },
                },
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    ease: [0.26, 0.08, 0.25, 1],
                    duration: 0.15,
                  },
                },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key={viewKey}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </div>
      </motion.main>
    </div>
  )
}
